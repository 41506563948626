.teaBooking {
  width: 600px;
  font-size: 1.5rem;
  margin: 5px 0px 15px 10px;
}
@media screen and (max-width: 1025px) {
  .teaBooking {
    width: 95%;
    font-size: 1.4rem;
    margin: 5px 0px 5px 10px;
  }
}
.ffdRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin: 0px 0px 15px 0px;
}

@media screen and (max-width: 1025px) {
  .ffdRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0px 0px 15px 0px;
  }
}
.TeaNoticeCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px 15px 0px;
  background-color: rgb(0, 87, 63);
  background-size: cover;
  border-radius: 10px;
  width: 500px;
  height: 600px;
  padding: 0px;
}
@media screen and (max-width: 1025px) {
  .TeaNoticeCol {
    width: 100%;
    padding: 15px 0px 15px 2px;
  }
}
.teaHdng {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  font-family: Rochester;
  font-size: 3rem;
  font-weight: bold;
  margin: 0px 0px 0px 0px;
  /*color: rgb(0, 87, 63);*/
  color: #ff073a;
}
@media screen and (max-width: 1025px) {
  .teaHdng {
    width: 70%;
    margin: 0px 0px 0px 0px;
  }
}
.teaDetails1 {
  width: 100%;
  height: auto;
  padding: 5px 5px 5px 5px;
  font-size: 1.3rem;
  font-family: roboto;
  margin: 10px 0px 10px 0px;
  color: white;
}
.teaDetails1 p {
  width: 100%;
  height: auto;
  padding: 5px 5px 5px 5px;
  font-size: 1.3rem;
  font-family: roboto;
  margin: 10px 5px 10px 5px;
  color: white;
}
@media screen and (max-width: 1025px) {
  .teaDetails1 {
    margin: 10px 10px 10px 0px;
    padding: 5px;
  }
}

.ffdCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  margin: 0px 0px 15px 0px;
}

@media screen and (max-width: 1025px) {
  .eventCol {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 100%;
    margin: 0px 0px 15px 0px;
  }
}

.ffdHdng {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  margin: 0px 0px 15px 0px;
}
.ffdp {
  margin-top: 10px;
  width: 95%;
  font-size: 1.5rem;
  color: black;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.ffdpB {
  margin-top: 10px;
  width: 100%;
  font-size: 1.3rem;
  letter-spacing: 1;
  color: black;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0px;
}
.ffdBuyOnline {
  width: auto;
  font-size: 1.5rem;
  background: green;
  color: white;
  padding: 15px;
  border-radius: 15px;
  font-weight: bold;
}
@media screen and (max-width: 1025px) {
  .ffdHdng {
    width: 95%;
    font-size: 1.5rem;
  }
  .ffdp {
    font-size: 1rem;
    letter-spacing: 0px;
    width: 95%;
  }
  .ffdpB {
    margin-top: 10px;
    width: 90%;
    font-size: 1.3rem;
    color: black;
    font-weight: bold;
    text-align: left;
    margin-bottom: 0px;
    padding: 0px 5px 0px 10px;
  }
}
.flexStdCol {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: auto;
  background: white;
}
.h2Format {
  text-align: center;
  width: 500px;
  height: auto;
  font-family: Besley;
  font-weight: 900;
  font-size: 1.7rem;
  margin-top: 15px;
  margin-bottom: 0px;
  color: rgb(0, 87, 63);
}
.photoTitle {
  text-align: center;
  width: 500px;
  height: auto;
  font-family: Besley;
  font-weight: 900;
  font-size: 1.5rem;
  margin-top: 0px;
  margin-bottom: 0px;
  color: rgb(0, 87, 63);
}
.photographer {
  text-align: center;
  width: 500px;
  height: auto;
  font-family: Besley;
  font-weight: 900;
  font-size: 1rem;
  margin-top: 0px;
  margin-bottom: 0px;
  color: rgb(0, 87, 63);
}

.eventStyle1 {
  margin: 0px 0px 15px 0px;
}

.eventStyle2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0px 0px 15px 0px;
}

@media screen and (max-width: 1025px) {
  .eventStyle2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    margin: 20px 0px 15px 0px;
  }
}

.hdngStd {
  font-size: 5rem;
  font-weight: 500;
  color: white;
  padding: 0.5rem;
  width: 70%;
  padding-bottom: 0.7rem;
  padding-top: 15px;
  padding-left: 1rem;
  font-family: "Road Rage";
  text-shadow: 1px 1px black;
  letter-spacing: 2px;
}

@media screen and (max-width: 1025px) {
  .hdngStd {
    width: 95%;
  }

  .paraStd {
    width: 95%;
  }

  .paraStd p {
    width: 95%;
  }
}

.paraStd {
  margin-top: 10px;
  width: 70%;
  font-size: 2rem;
  letter-spacing: 1;
  color: white;
  text-align: left;
  margin-bottom: 10px;
}

.paraStd p {
  margin-bottom: 10px;
}

.eventUl {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 70%;
}

.eventUl li {
  display: list-item;
  list-style-type: circle;
  font-size: 1.5rem;
  color: white;
  width: 100%;
}

@media screen and (max-width: 1025px) {
  .eventUl {
    width: 100%;
  }

  .eventUl li {
    display: list-item;
    list-style-type: circle;
    font-size: 1.5rem;
    color: white;
    width: 100%;
  }
}

.eventUl li {
  display: list-item;
  list-style-type: circle;
  font-size: 2rem;
  color: white;
}

.event-flex-cntnr {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
}

.spacing {
  letter-spacing: 1rem;
}

.eventHdg {
  box-sizing: border-box;
  font-family: Arvo;
  font-weight: 700;
  padding: 0.5rem;
  width: 100%;
}

.eventImg {
  display: block;
  margin: 20px 0px 0px 0px;
  max-width: 100%;
  max-height: 80vh;
  /*height: 75vh;*/
}
@media screen and (max-width: 1025px) {
  .eventImg {
    margin: 10px 0px 0px 0px;
    /*max-height: 100vh;*/
    max-width: 100%;
  }
}

.event-flex-name p {
  margin-top: 0px;
  box-sizing: border-box;
  font-size: 2rem;
  font-family: corben;
  letter-spacing: 1 rem;
  color: red;
}

.noEvents {
  font-size: 2rem;
  font-weight: bold;
}

.noEvents p {
  color: grey;
}

.event-img-responsive {
  width: 100%;
  height: 100%;
}

.mc4Img {
  /* height: 90vh;
  max-width:100%;*/
  height: 50%;
  width: auto;
}

@media screen and (max-width: 1025px) {
  .event-flex-unit {
    width: 100%;
    box-sizing: border-box;
    height: auto;
    margin: 5px;
  }

  .event-flex-unit p {
    width: 90%;
    box-sizing: border-box;
    font-size: 1.5rem;
  }

  .event-img-responsive {
    width: 50%;
    height: auto;
  }
}

.event-name {
  font-family: corben;
  font-weight: bold;
  font-size: 3rem;
  color: red;
}

.map-temp {
  margin-top: 50px;
}

.event-intro-panel1 {
  width: 100%;
  /*box-sizing: border-box;*/
  height: auto;
  margin: 20px 0px 20px 0px;
  /* background-image: linear-gradient(to right, rgba(255,236,206,.9),rgba(125,254,227,.9),rgba(139,164,251,.9)), 
    url("../img/houseevents.jpg");*/
}

.event-panel-title {
  font-family: "Oxygen";
  /*font-family: "Corben";*/
  font-size: 3.5rem;
  font-weight: 600;
  color: rgb(126, 30, 121);
  padding: 90px 10px 20px 80px;
  width: 200px;
  height: 4rem;
}

.event-bkgnd {
  /*background:rgb(36,82,46);*/
  background-image: linear-gradient(
      to right,
      rgba(242, 38, 19, 0.6),
      rgba(242, 38, 19, 0.7)
    ),
    url("../img/eventsbkgnd.JPG");
  border-radius: 10px;
  width: 50%;
  height: 450px;
  padding: 15px;
  margin-bottom: 10px;
}

@media screen and (max-width: 1025px) {
  .event-bkgnd {
    width: 90%;
    height: auto;
    padding: 10px 10px 0px 10px;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.event-bkgnd p {
  font-weight: normal;
  font-size: 1.5rem;
  color: white;
  padding-bottom: 20px;
}

.bold-it {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
}

.event-test {
  background: green;
}

.event-title {
  width: 60%;
}

@media screen and (max-width: 1025px) {
  .event-title {
    width: 100%;
  }

  .event-title p {
    font-family: "Lora", serif;
    color: white;
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
  }
}

/*.eventFrontBkgnd {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100vh;
     background: linear-gradient(rgba(254,254,51,.2), 30%,rgba(254,254,51,.2)),
     url("../img/events/comingsoona.png") no-repeat center center fixed;
     background-size:cover;
   }
   @media screen and (max-width: 1025px) {
    .eventFrontBkgnd {
      position:fixed;
      top:0;
      left:0;
       width:100%;
      height:100vh;
       background: linear-gradient(rgba(254,254,51,.2), 30%,rgba(254,254,51,.2)),
       url("../img/events/comingsoona.png") no-repeat center center fixed;
       background-size:cover;
     }
    }*/
.eventsHdrFlex {
  display: flex;
  flex-direction: column;
  /*border-radius:5px;*/
  align-items: center;
  position: fixed;
  top: 70px;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
}

@media screen and (max-width: 1025px) {
  .eventsHdrFlex {
    top: 90px;
  }
}

.eventsGazebo {
  width: 60%;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  margin-top: 70px;
  border-radius: 10px;
}

.eventsGazebo p {
  font-family: "Lora", serif;
  color: white;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
}

@media screen and (max-width: 1025px) {
  .eventsGazebo {
    width: 100%;
  }

  .eventsGazebo p {
    font-family: "Lora", serif;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }
}

.eventsGoContact {
  width: auto;
  text-align: center;
  padding-top: 15px;
}

.eventsGoContact p {
  font-family: "Lato", serif;
  color: white;
  font-size: 1.5rem;
  background: green;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
}

@media screen and (max-width: 1025px) {
  .eventsGoContact p {
    font-size: 1rem;
    background: green;
    padding: 5px;
  }
}

.eventsMorris {
  width: 60%;

  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  margin-top: 20px;
  border-radius: 10px;
}

.eventsMorrisContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.eventsMorrisTitle {
  font-family: "Lora", serif;
  color: red;

  font-size: 3rem;
  border-radius: 15px;
  font-weight: bold;
  text-align: center;
  margin-top: 30px;
  padding-bottom: 0px;
  width: 40%;
  background: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    )
    no-repeat center center fixed;
}

.eventsMorrisInfo {
  background: linear-gradient(rgba(255, 255, 0, 0.9), rgba(255, 255, 0, 0.9))
    no-repeat center center fixed;
  position: fixed;
  bottom: 0;
  font-family: "Lato", serif;
  padding: 10px;
  border-radius: 15px;
  color: green;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

@media screen and (max-width: 1025px) {
  .eventsMorrisTitle {
    position: fixed;
    top: 30%;
    width: 100%;
    padding-top: 20px;
    font-size: 2rem;
    padding-bottom: 20px;
  }

  .eventsMorrisInfo {
    font-family: "Lora", serif;
    color: green;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
  }
}
