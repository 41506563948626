.donationsFlexCntnr {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  height: auto;
  margin: 80px 10px 10px 10px;
}
.donationsFlexCntnr p {
  color: black;
  padding: 1rem;
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 1rem;
}
@media screen and (max-width: 1000px) {
  .donationsFlexCntnr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: auto;
  }
  .donationsFlexCntnr p {
    color: black;
    padding: 1rem;
    font-size: 1.5rem;
    line-height: 2rem;
    padding: 1rem;
  }
}
.donatIntro {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: auto;
}
.benefitStmnt {
  width: 150px;
  height: auto;
}
/*.collageCntnr {
  display: flex;
  flex-direction: row;
  position: relative;
}*/
.donatBtn {
  display: flex;
  flex-direction: column;
  width: 400px;
}
.benefits {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 200px;
  font-family: Lato;
  font-weight: 400;
  font-style: normal;
  margin: 0px 5px 20px 15px;
}
.benefitStmnt {
  width: 45%;
  font-family: Arial;
  color: black;
  font-size: 2rem;
  margin: 10px 10px 10px 10px;
}
/*.hdrImages {
  width: 200px;
  height: 200px;
  margin: 5px 10px 10px 10px;
}*/
/*.collageFlex {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 5px;
}
.collageItem {
  position: relative;
  color: white;
}*/

.donatImgs {
  width: 250px;
  height: 250px;
  margin: 5px 10px 5px 10px;
}
/*.photoText {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0px 10px 0px 15px;
  text-shadow: 2px 2px 5px black;
}
.photoText p {
}*/
.condMsgOrDsply {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.donationCheckout {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #242d60;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Helvetica Neue", "Ubuntu", sans-serif;
  height: auto;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.product {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: auto;
  border-radius: 15px;
  background: rgb(101, 141, 27);
  /*background: rgb(0, 87, 63);*/
  align-items: center;
  padding: 10px;
}
.productDescription {
  font-size: 1.6rem;
  font-weight: bold;
}
.productDescription p {
  color: white;
  font-family: Besley;
  text-align: center;
  /*font-size: 2rem;
  font-weight: bold;*/
}
.msgBox {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: auto;
  border-radius: 15px;
  color: white;
  background: rgb(101, 141, 27);
  padding: 5px 10px 10px 5px;
}
.msgBox p {
  color: white;
}

/*p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.154px;
  color: #242d60;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}*/
.logo {
  border-radius: 6px;
  margin: 10px;
  width: 120px;
  height: 80px;
}
h3,
h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /*letter-spacing: -0.154px;*/
}
h5 {
  opacity: 0.5;
}
button {
  height: 40px;
  background: rgb(0, 87, 63);
  color: white;
  width: 100%;
  font-size: 20px;
  border: 0;
  font-weight: bold;
  cursor: pointer;
  letter-spacing: 0.6;
  border-radius: 0 0 6px 6px;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
}
button:hover {
  opacity: 0.8;
}
