/*MODAL.js css*/
html {
  background-color: black;
  background-size: cover;
}
.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: black;
  background-size: cover;
  position: fixed;
  top: -10px;
  left: 0px;
  bottom: 0px;
  z-index: 1;
}
/*@media screen and (max-width: 1025px) {
	html { 
		/*background-color:black; */
/*background-size: cover;
	  }
}*/
.titleCloseBtn {
  position: fixed;
  top: 110px;
  left: 35px;
  background: grey;
  width: 30px;
  border-radius: 20px;
  z-index: 2;
  color: white;
  font-size: 2rem;
  font-weight: 600;
}
/*.closeBtn {
	background:transparent;
	font-size:1.5rem;
	color:white;
	border:none;
  }*/

.modalContainer {
  width: 100vw;
  height: 100vh;
  background-color: black;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: centre;
  margin: 10px 0px 0px 0px;
}
.modalImg {
  max-width: 50%;
  max-height: 85%;
  padding: 40px 10px 0px 0px;
}
@media screen and (max-width: 1025px) {
  .modalContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 0px 0px 0px;
    position: sticky;
    top: 100px;

    /*align-items:center;*/
  }
  .modalImg {
    max-width: 85%;
  }
  /*.modalImg p {
width:95%;
	}*/
}

.respModal {
  max-width: 100%;
  max-height: 95%;
}

/*.modalContainer .img {
	max-height: 60%;
	max-width: 100%;
}*/

.modalInfo {
  width: 320px;
  height: 500px;
  margin: 15px 5px 0px 5px;
}

.modalHdng p {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  text-align: left;
  margin-top: 20px;
}

.modalDtls p {
  font-size: 1.3rem;
  font-weight: normal;
  color: white;
}
