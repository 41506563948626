.Navbar {
  display: flex;
  /*flex-direction:row;*/
  justify-content: space-between;
  align-items: center;
  /* by default: align-items: strech -> child elements' heights = the container's height */
  height: 70px;
  background-color: rgb(101, 141, 27);
  position: fixed;
  z-index: 10;

  top: 0px;
  width: 100vw;
}

.navbarLinks {
  flex-grow: 4;
  background-color: transparent;
}
.navbar-links {
  display: flex;
  justify-content: center;
}
/*.opened {
    display: flex;
    justify-content: center;
  }*/
.navbarMenu {
  display: flex;
  justify-content: space-between;
}
.navbar-link {
  box-sizing: border-box;
  border-radius: 10px;
  margin-left: 20px;
  margin-right: 20px;
  /*color:rgb(99,81,61);*/
  color: rgb(255, 255, 255);
  height: 60px;
  background-color: white;
  font-size: 2rem;
  font-family: "Road Rage";
  font-weight: Normal;
  /*text-shadow: .5px .5px black;*/
  padding: 5px 10px 5px 10px;
  /* background:rgba(34,139,34,.6);*/
  background: transparent;
}
.active-link {
  box-sizing: border-box;
  border-radius: 10px;
  margin-left: 20px;
  margin-right: 20px;
  color: white;
  height: 60px;
  background-color: rgb(0, 87, 63);
  font-size: 2rem;
  font-family: "Road Rage";
  font-weight: Normal;
  /*text-shadow: .5px .5px black;*/
  padding: 2px 10px 3px 10px;
  /*background:transparent;*/
}
.navbar-link-donate {
  box-sizing: border-box;
  border-radius: 15px;
  margin-left: 10px;
  margin-right: 20px;
  padding: 5px 20px 5px;
  background-color: orange;
  color: white;
  font-size: 2rem;
  font-weight: bold;
}

.navbar-link:hover {
  color: rgb(0, 87, 63);
  /*font-weight:900;*/
}
/*.navbar-selected {
    color:yellow;
    font-size:1.5rem;
    font-weight:bold;
    padding:5px 10px 5px 10px;
    border-radius:10px;
  }*/

.toggle {
  display: none;
  padding-left: 15px;
  padding-top: 15px;
  font-size: 16px;
}
.navbar-info {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.navbar-logo {
  /*width: auto;*/
  width: 120px;
  height: 80px;
  margin: 10px 10px 0px;
  padding: 0px;
  background: transparent;
  /* background: linear-gradient(rgba(255,255,255,.7),rgba(255,255,255,.5));*/
  position: fixed;
  z-index: 10;
  top: 0px;
  left: 20px;
}
.navbar-logo img {
  width: 100%;
  height: 100%;
  opacity: 1;
}
/* .navbar-title {
    font-size: 2.5rem;
    padding-bottom: 0rem;
    padding-top: 15px;
    padding-left: 1rem;
    width: 275px;
    font-family:'Road Rage';
    text-shadow: 1px 1px black;
    letter-spacing:2px;

  }
  .navbar-title p {
    /*color:rgb(50,205,50);
        color:rgb(142,58,89);
    font-weight:normal;
  }
  */
/**
  *
  * Smaller screen + Mobile
  *
  */
@media screen and (max-width: 1025px) {
  /* ; for this media, the top line logo, title and menubutton needs to change to a non flexbox inline, so logo and title are together, and the menubtn is on the right side
    Note
    */
  .Navbar {
    display: flex;
    flex-direction: row; /* change the direction of the container */
    justify-content: space-between;
    height: 70px;
    width: 100%;
    position: fixed;
    /* overflow-x: hidden;*/
    z-index: 10;
    top: 0px;
    left: 0px;
    margin-bottom: 20px;
  }

  .navbar-links {
    flex-direction: column;
    height: auto;
    flex-wrap: nowrap;
    background-color: rgb(255, 255, 255);
    width: 0;
    opacity: 0.9;
    position: fixed;
    top: 70px;
    left: 0px;
    z-index: 9;
    overflow-x: hidden;
    transition: 0.3s;
    padding-bottom: 800px;
    padding-top: 30px;
    /*This is kludgy, but covers up the content behind the navbar-links - 
      solution is to blur the content below  */
  }

  .navbar-links.closed {
    /* when toggled, hide the main nav*/
    /* display: none; */
    width: 100%;
    opacity: 1;
  }
  .navbar-logo {
    margin: 5px 0px 0px 5px;
    z-index: 10;
  }

  .navbar-title p {
    font-size: 2rem;
    color: rgb(50, 205, 50);
  }

  .toggle {
    display: block;
    padding-bottom: 2em;
  }

  .navbar-link {
    display: block;
    padding: 20px 30px 10px;
    margin: 15px 10px 10px 20px;
    color: rgb(0, 87, 63);
    text-shadow: 0px 0px black;
    font-weight: normal;
    font-size: 2.5rem;
  }
  .active-link {
    box-sizing: border-box;
    border-radius: 10px;
    margin: 30px 10px 5px 40px;
    color: white;
    height: auto;
    background-color: rgb(0, 87, 63);
    font-size: 2.5rem;
    font-family: "Road Rage";
    font-weight: Normal;
    /*text-shadow: .5px .5px black;*/
    padding: 3px 10px 3px 10px;
    /* background:rgba(34,139,34,.6);*/
  }
  .navbar-link:hover {
    color: black;
    font-weight: normal;
  }
  .navbar-link:activated {
    color: white;
  }
  .navbar-link-donate {
    display: inline-block;
    padding: 10px 10px 10px 50px;
    margin-top: 10px;
    border-bottom: 1px solid grey;
    box-sizing: border-box;
    border-radius: 10px;
    margin-left: 20px;
    margin-right: 10px;
    background-color: orange;
    color: white;
    font-size: 1.5em;
    font-weight: 600;
  }
  .cartImg {
    margin-left: 0px;
  }
  .navbarMenuBtn {
    position: fixed;
    top: 0px;
    left: 150px;
    color: white;
    background: transparent;
    height: 70px;
    padding: 0px 0px 0px 0px;
    margin: 0px 20px 0px 0px;
  }
}

/* reset style */
body,
ul {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #484848;
}

button {
  background-color: transparent;
  border: none;
  color: white;
}

button:focus {
  outline: 0;
  color: white;
}

.App {
  font-family: sans-serif;
  text-align: center;
}
