body {
	background-color:white;
}

.cartBkgnd {
    /*background: rgb(136, 133, 134);*/
}

.storeBkgnd {
   /*background: rgb(240,255,240);*/
    background: #ffffff;

}
.storTop {
	display:flex;
	flex-direction: row;
	width:100%;
	justify-content: center;
}
.img-ctr {
    /* margin-left: auto/;
    margin-right: auto;*/
    max-width: 100%;
    max-height:245px;
    /*width:auto;*/
    margin-top:30px;
    /*border-radius:10px;*/
}
@media screen and (max-width: 1025px) {
    .img-ctr {
        width:90%;
    }
}


.ctrBtn {
    margin-left: auto;
    margin-right: auto;
}

.ulCtgry {
    display: flex;
    width: 90%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    /*box-sizing:border-box;*/
    border-bottom: 2px solid rgb(0, 0, 139);
}

.iCtgry {
    color: white;
    font-size: 1.0rem;
    font-weight: bold;
    padding: 20px 5px 5px;
}

.liCtgry {
    box-sizing: border-box;
    border-radius: 15px;
    width: 150px;
    cursor: pointer;
    height: 75px;
    margin: 15px 5px 20px 5px;
}

.btnCtgry i {
    box-sizing: border-box;
    border-radius: 5px;
    width: 150px;
    height: 75px;
    margin: 0px 5px 0px 00px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    color: white;
}

.lin2Ctgry {
    /*display:block;*/
    padding: 0px 0px 0px;
}

.storeProdArea {
    width: 100%;
	text-align:center;
}

.storeFlexBanner {
    /*WAS fLEX1*/
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 90%;
}

.storeFlexTop {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    width: 100%;
	height: auto;
}
@media screen and (max-width: 1025px) {
    .storeFlexTop {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        margin: 15px 5px 10px 5px;
        padding:5px 5px 5px 3px;
    }
}


.storeCatgSize {
    width: 40%;
}

.storeCategories {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 90px;
    left: 60px;
    width: 10%;
    align-items:center;
    /*justify-content: space-evenly;*/
    margin: 10px 15px 15px 0px;
    padding: 10px 15px 15px 0px;
}

@media screen and (max-width: 1025px) {
    .storeCategories {
        display:flex;
        flex-direction: row;
		position:fixed;
        flex-wrap: wrap;
        /*position: static;*/
        top: 0px;
        left: 0px;
        width: 100%;
        /*align-items:center;*/
        justify-content:flex-start;
        padding:10px 10px 10px 10px;
        margin: 90px 15px 30px 0px;
        background: white;
    }
}
.storCtgBtn {
    color:white;
    font-size: 1.2rem;
    font-weight: bold;
  }

.storeCategoryItems {
    box-sizing: border-box;
    border-radius: 15px;
    width: 100px;
    cursor: pointer;
    height: 60px;
    margin: 10px 10px 10px 0px;
    padding: 5px 5px 5px 5px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align:center;
}


/*.storeSize {
    width:80%;
    height:auto;

}*/

.store-item1 {
    width: 50%;
    padding: 15px;
}

.store-Flex2 {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 5px;
}
@media screen and (max-width: 1000px) {
    .store-Flex2 {
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top:170px;
    }
}
.storeProducts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content:flex-start;
    box-sizing: border-box;
    background: white;
    width: 300px;
    height: 450px;
    /*border-radius:5px;*/
    margin: 25px 25px 0px 0px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    /*border: none !important;*/
    /* border-style: none none solid none;
  border-color:none none grey none;*/

}
.productTop {
    height:275px;
    width:100%;
    background:rgba(211,211,211,.2);
}
@media screen and (max-width: 1025px) {
    .storeProducts {
        margin: 10px 0px 0px 10px;
        cursor: pointer;

    }
}

/*@media screen and (max-width: 700px) {
    .store-Flex2 li {
      width:90%;
    }
}*/
.qtyDiv {
    margin-top: 60px;
    height: 100px;
}

li.storeProducts:hover {
    background: linear-gradient(rgba(241,230,178, .1), rgba(241,230,178, .4));

    cursor: pointer;
    /* Note:attempted to use :active to simulate hover on mobile devices,
    did not work and requires more research*/
}


/*.storeProducts .price {
    
    font-size: 1.1rem;
    font-weight: 600;
    color: black;
    margin-top: 30px;
    text-align: center;
}*/

.storeMessage {
    width: 80%;
    font-size: 1.5rem;
    color: grey;
    text-align: center;
    margin-bottom: 25px;
}

.prodDetFlex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    margin:0px 0px 0px 0px;
}
@media screen and (max-width: 1000px) {
    .prodDetFlex {
        flex-direction: column;
        align-items:center;
    }
}

.prodDetPanel1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    box-sizing: border-box;
}
@media screen and (max-width: 1000px) {
    .prodDetPanel1 {
        align-items:center;
        width: 90%;
    }
}

.prodDetName {
    box-sizing: border-box;
    text-align: left;
    overflow:hidden;
    height: auto;
    font-family:'Times New Roman';
    font-size: 2rem;
   color: black;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;
    padding:0px 5px 0px 5px;
}
@media screen and (max-width: 1000px) {
    .prodDetName {
        font-size:1.7rem;
        margin-bottom: 0px;
    }
}

.prodDetPanel2 {
    display: flex;
    flex-direction: column;
   align-items: center;
    width: 100%;
    height: auto;
}

.prodDetails {
    width:60%;
    height:60px;
    margin-top:10px;
    margin-bottom:30px;
    color:black;
    font-size:1.4rem;
    padding:5px;
}
@media screen and (max-width: 1000px) {
    .prodDetails {
      width:90%;
    }
}
.prodDetQty {
    padding:15px;
    margin:10px;

}
.detPrice {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 25px;
}
.detQtyTitle {
font-size: 1.4rem;
font-weight:600;
}
.detPickupNote {
    background:red;
  color:white;
  width:50%;
    height:auto;
    margin:10px 0px 60px 0px;
    font-size:1.4rem;
    padding:10px;
}
@media screen and (max-width: 1000px) {
    .detPickupNote {
        width:90%;
    }
}

.prodDetSubmit {
    background: green;
    color: whitesmoke;
    border: none;
    margin: 10px 0px 15px 0px;
    padding: 15px 40px 15px 40px;
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 1px;
    width: 300px;
    height: auto;
    cursor:pointer;
}

.cartHdng {
    font-size: 1.7rem;
    color: black;
    text-align: center;
}

.cartHdng p {
    font-size: 1.8rem;
    color: black;
    font-weight: bold;
}

.cartFlex1 {
    display: flex;
    width: 90%;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.cartItem1 {
    box-sizing: border-box;
    border-radius: 5px;
    width: 50%;
    padding: 10px;
    background: white;
    color: black;
}

@media screen and (max-width: 1025px) {
    .cartFlex1 {
        flex-direction: column;
        width: 100%;
        /*align-items: center;*/
        justify-content: center;
    }
}
.singleLine {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 25px;
    padding-top: 20px;
    padding-bottom: 10px;
}

.cartImg {
    font-size: 1.5rem;
    padding-right: 10px;
}

.cartItems {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: auto;

}

.liCntrl {
    list-style-type: none;
}

.cartLiId {
    display: none;
}

.cartItem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    height: auto;
}
.cartLeftSide {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
}

.cartRightSide {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 70%;
}

.cartName {
    font-size: 1.3rem;
    font-weight:bold;
    width: 250px;
    color: black;
    margin-bottom:10px;
}

.cartPrice {
    margin-left:5px;
    margin-bottom:10px;
    display: inline-block;
    font-size: 1.2rem;
    font-weight: 600;
    color: black;
    text-align: center;
}
/*.cartQtyTitle {
    font-size: 1.2rem;
    font-weight: normal;
    color: black;
}*/

.Qty {
    font-size: 1.2rem;
    font-weight:500;
}

.qtyDesc {
    padding-top: 20px;
    font-size: 1.2rem;
}

.msgToCart {
    /*appearance: none; for platform-native styling not using ??*/
    position: fixed;
    top: 70px;
    left: 0;
    z-index: 10;
    background-color: #246bec;
    border: 1px solid #246bec;
    border-radius: 0;
    box-sizing: border-box;
    color: black;
    display: none;
    font-size: 2em;
    font-weight: 500;
    margin-bottom: 1em;
    margin-top: 1em;
    padding: .5em;
    width: 50%;
    transition: border-color, background-color 300ms ease-in-out;
}

.cartLiImg {
    width: 70%;
    height: auto;
}

.cartQtyTitle {
    box-sizing: border-box;
    width: 1rem;
    height: 8px;
    font-size: 1.2rem;
    color: black;
}
.cartPriceTitle {
    box-sizing: border-box;
    width: 1rem;
    height: 10px;
    font-size: 1.2rem;
    color: black; 
}
.cartQtyAmnt {
    margin-left:5px;
    margin-bottom:10px;
    width: 2.5rem;
    height: 30px;
    font-size: 1.2rem;
    font-weight:500;
    color: black;
}

.deleteBtn {
    font-size: 1rem;
    padding: 5px;
    margin:0px 0px 40px 25px;
    background: red;
    color: white;
    width: 70px;
}

.deleteBtn:hover {
    background: grey;
    cursor: pointer;
    color: white;
}

.cartCheckout {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cartHeadings {
    width: 200px;
    display: inline;
    font-size: 1rem;
}

.cartSubTitle {
    font-size: 1.2rem;
    color: black;
}

.cartSubtotal {
    font-size: 1rem;
    font-weight: bold;
    color: black;
}

.subttlPosition {
    /* width:90%;*/
    height: 30px;
    padding-top: 20px;
    /* flex-direction: row;
       align-items:center;
       justify-content: flex-end;*/
}
.productId {
    display: none;
}

.productName {
    /*box-sizing: border-box;*/
    text-align: center;    
    height: 45px;
    font-family:'Times New Roman';
    font-style:normal;
    font-size: 1.3rem;
    color: black;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    padding:0px 5px 0px 5px;
}

@media screen and (max-width: 1000px) {
    .productName {
        margin-bottom: 0px;
    }
}

.productQty {
    font-size: 1.5rem;
}

 .price {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 25px;
    text-align: center;
}

.btn-order {
    height: 70px;
    width: 300px;
    margin-top: 20px;
    background: orange;
    font-size: 1.5rem;
    font-weight: bold;
    border-radius: 7px;

}
.btn-order:hover {
    background:green;
    cursor: pointer;
    color:white;
}
.chkoutTitle {
    font-size: 1.1rem;
}

.taxesPosition {
    padding-top: 10px;
}

.chkoutTaxes {
    font-size: 1.1rem;
}

.chkoutAmnt {
    font-size: 1.1rem;
}

.confirmHdg {
    background: grey;
    margin-top: 100px;
}

.confirmFlex1 {
    flex-direction: column;
    width: 95%;
    align-items: center;
    /*justify-content:center;*/
}

.spcL3 {
    box-sizing: border-box;
    margin-left: 30px;
}

.confirmTitle {
    font-size: 1.5rem;
    font-weight: bold;
    color: black;
    text-align: center;
    margin-bottom: 10px;
}
.confirmPara {
    box-sizing: border-box;
    text-align: center;
    width: 90%;
    font-size: 1rem;
    color: black;
    padding-left: 30px;
    margin-bottom: 10px;
}

.confirmAddr {
    width: 90%;
    box-sizing: border-box;
    background: white;
    font-size: 1rem;
    color: black;
    text-align: center;
    margin-left: 10px;
}

.pyplchckt {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}