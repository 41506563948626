.joinUs-cntnr {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: white;
  width: 100%;
}
.joinUs-unit {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  height: auto;
  box-sizing: border-box;
  padding: 10px;
}
.joinUs-gallery {
  display: flex;
  flex-flow: row wrap;
  /*align-content:space-around;*/
  /*align-content:flex-start;*/
  justify-content: center;
  /* width:50%;*/
  box-sizing: border-box;
}
.joinUs-img {
  width: 300px;
  height: auto;
  padding: 3px;
  margin: 0px;
}
.respnsiv-img {
  width: 100%;
}
.border-green {
  border: 1px solid green;
}
.joinUs-unit p {
  color: black;
  padding: 0rem 2rem 1rem 1rem;
  font-size: 1.3rem;
  line-height: 1.8rem;
  /*text-align:justify;*/
}
@media screen and (max-width: 1025px) {
  .i-top {
    margin-top: 90px;
    background-color: transparent;
  }
  .joinUs-cntnr {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .joinUs-unit {
    width: 100%;
    /*box-sizing: border-box;*/
    height: auto;
    /*padding: 0px 15px 0px 5px;*/
  }

  /* .joinUs-unit {
        flex-direction:column;
      }*/
  .joinUs-unit p {
    color: black;
    /*padding: 1rem;*/
    padding: 0px 5px 10px 5px;
    font-size: 1.4rem;
    line-height: 1.3;
  }
}
.joinUs-text-hdng {
  font-family: Arvo;
  color: black;
  font-weight: bold;
  padding: 1rem;
  font-size: 2rem;
  line-height: 3.5rem;
}
.callout {
  padding: 1rem 2rem 1rem 2rem;
  font-size: 2.2rem;
  line-height: 3.5;
}
.callout p {
  font-style: italic;
  font-weight: 600;
}
.joinUsHdr {
  font-size: 2rem;
  font-weight: bold;
}
.joinUs-button {
  background: orange;
  color: white;
  text-transform: uppercase;
  border: none;
  margin-top: 10px;
  padding: 20px;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 1px;
}
.joinUsPaymentCntnr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.membershipDownload {
  width: 300px;
  font-family: Oxygen;
  font-size: 1.7rem;
  font-weight: 500;
  color: white;
  padding: 15px;
  background: rgba(101, 198, 187);
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}
.membershipDownload a {
  color: white;
}
.testPdf {
  width: 700px;
  height: 500px;
}
