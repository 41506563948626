.contactUs-form {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: auto;
	align-items: center;
}

.contactUs-form form {
	box-sizing: border-box;
	max-width: 500px;
	/*margin: 0 auto;*/
	/*align-items: center;*/
	margin: 0px 0px 0px 20px;
	background-color: rgb(241, 230, 178);
	/*background-image: linear-gradient(to right, rgba(255,215,0,.8),rgba(255,165,0,.7));*/
	box-shadow: 5px 5px 4px #D0D0D0;
	border-radius: 5px;
}

.contactUs-form button {
	background: orange;
	color: white;
	text-transform: uppercase;
	border: none;
	margin: 15px 0px 15px 25px;
	padding: 10px;
	font-size: 1.3rem;
	font-weight: bold;
	letter-spacing: 1px;
	width: 180px;
	height: auto;
}

.contactUs-form input {
	padding: .4rem;
	border: 1px solid #999;
	margin: 15px 0px 0px 25px;
	font-size: 1.2rem;
	width: 450px;
	height: 40px;
}

.contactUs-form textarea {
	padding: .4rem;
	border: 1px solid #999;
	margin: 15px 0px 0px 25px;
	font-size: 1.2rem;
	width: 450px;
	height: 175px;
}


@media screen and (max-width: 1025px) {
	.contactUs-form {
		width: 95%;
	}

	.contactUs-form input {
		width: 80%;
	}

	.contactUs-form textarea {
		width: 80%;
	}

	.contactUs-form form {
		width: 95%;
		margin: 3px 0px 5px 0px;
		padding: 5px 2px 5px 0px;
	}

}

/*.contactUs-form input[type="submit"]:hover {
      background: #bf1650;
    }*/

/*.contactUs-form input[type="button"]:active,
   .contactUs-form input[type="submit"]:active {
      transition: 0.3s all;
      transform: translateY(3px);
      border: 1px solid transparent;
      opacity: 0.8;
    }*/

.contactUs-form input:disabled {
	opacity: 0.4;
}

/* .contactUs-form input[type="button"]:hover {
      transition: 0.3s all;
    } */

/* .contactUs-form input[type="button"],
    .contactUs-form input[type="submit"] {
      -webkit-appearance: none;
    }*/
/*  .contactUs-form input[type="textarea"] {
        height: 120px;
      } */
.contactUs-form .error {
	width: 400px;
	padding: 0px;
	margin-bottom: 0px;
	color: orange;
	font-size: 1.5rem;
}

/*.cntctFrmLlbl {
	line-height: 2;
	text-align: left;
	display: inline;
	background-color: white;
	color: rgb(2, 121, 177);
	font-size: 1.3rem;
	font-weight: bold;
}

label p {
	color: rgb(2, 121, 177);
} */

.contactTxt {
	color: black;
	margin: 10px 5px 5px;
	font-size: 1.5rem;
}

.contactDoneCntnr {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 30px;
}

.contactDone {
	background: green;
	font-size: 1.5rem;
	line-height: normal;
	width: 400px;
	height: auto;
	margin: 10px 10px 15px;
	padding: 10px;
}

.contactDone p {
	color: white;
}

@media screen and (max-width: 1025px) {
	.contactDone {
		margin: 5px 5px 5px;
	}
}

.accLin {
	display: block;
	color: black;
}
.mapCntnr {
		display: flex;
		flex-direction: column;
		margin:0px 40px 0px 55%;
		height: auto;
		align-items: center;
	}