.expFlexCntnr {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: nowrap;
  margin-top: 15px;
}

@media screen and (max-width: 1025px) {
  .expFlexCntnr {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.expMenu {
  display: flex;
  flex-direction: column;
  width: 20%;
  align-items: flex-start;
  margin-left: 15px;
  margin-top: 10px;
}
.expMenuItems {
  box-sizing: border-box;
  border-radius: 15px;
  /*width:150px;
  height:80px;*/
  width: 100px;
  height: 60px;
  cursor: pointer;
  margin: 10px 10px 10px 15px;
  padding: 5px 0px 10px 10px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 1025px) {
  .expMenu {
    display: flex;
    flex-direction: row;
    width: 400px;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-left: 5px;
    margin-top: 10px;
  }

  .expMenuItems {
    margin: 0px 10px 10px 0px;
  }
}

.expArticle {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*width: 80%;*/
  width: 1024px;
  height: auto;
  margin-left: 10px;
}

@media screen and (max-width: 1025px) {
  .expArticle {
    width: 100%;
    margin-bottom: 15px;
    margin-left: 0px;
  }
}

.expCarousel {
  box-sizing: border-box;
  border-radius: 15px;
  width: 100%;
  height: auto;
  margin: 15px 0px 20px;
}

.expContent {
  box-sizing: border-box;
  margin-top: 0px;
  color: black;
  line-height: 1.5;
  font-size: 1.3rem;
  text-align: justify;
}

.expContent p {
  padding: 0px 15px 10px 5px;
}

.exp-video {
  width: 95%;
  height: 450px;
  padding: 10px 5px 10px 5px;
}

@media screen and (max-width: 1025px) {
  .expContent {
    font-size: 1.5rem;
    line-height: 1.4;
    padding: 10px;
  }

  .expContent p {
    padding: 0px 15px 10px 5px;
  }
}

figcaption {
  background: rgb(240, 240, 240);
  color: black;
  font-size: 1.2rem;
  padding: 15px 10px 15px 15px;
  margin-top: -20px;
}

/*.exp-top-margin {
margin-top:70px;
}*/
.expTitle {
  box-sizing: border-box;
  font-size: 1.8rem;
  font-weight: bold;
  margin: 10px 15px 10px 15px;
  padding-top: 10px;
}

@media screen and (max-width: 1025px) {
  .expTitle {
    border-top: 1px solid black;
  }
}

.expSubHdng {
  box-sizing: border-box;
  font-size: 1.8rem;
  font-weight: bold;
  margin: 15px 15px 15px 0px;
}

@media screen and (max-width: 1025px) {
  .expSubHdng {
    font-size: 1.4rem;
    margin: 15px 10px -5px 0px;
  }
}

.exp-img-responsive {
  /*display:block;*/
  object-fit: cover;
  margin-top: 20px;
  margin-bottom: 20px;
  /*margin-left:15px;*/
  width: 100%;
  height: auto;
}

.break {
  flex-basis: 100%;
}

.iDescript {
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
}

.bkgndImg {
  /*note:failed attempt to applylinear gradient to backgroundimage*/
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}

.map-temp {
  margin-top: 50px;
}

.exp-sub-col {
  display: flex;
  flex-direction: column;
  width: 95%;
}

.expSubImgCntnr {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.expSubImg {
  max-width: 300px;
  height: auto;
  margin-top: 30px;
  margin-left: 20px;
}

.expSubImg img {
  width: 100%;
  height: auto;
}

.exp-back-btn {
  background: rgb(2, 121, 177);
  color: white;
  width: 300px;
  margin-left: 10px;
}

.exp-back-btn i {
  font-size: 1.5rem;
}

.exp-back-left {
  padding-left: 15px;
  padding-right: 15px;
}

@media screen and (max-width: 1025px) {
  .i-top {
    margin-top: 80px;
  }

  .exp-cntnr-btn {
    position: absolute;
    top: 0;
    z-index: 10000;
    background: rgb(2, 121, 177);
    width: 100%;
    /* height:50px;*/
  }
}
.beesThumbnailsCntnr {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 1000px;
  height: 500px;
  margin: 30px 0px 20px 0px;
}
@media screen and (max-width: 1025px) {
  .beesThumbnailsCntnr {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 100%;
    height: auto;
    margin: 0px 0px 10px 0px;
  }
}
.beesThumbnailCard {
  width: 450px;
  height: auto;
  margin: 0px 50px 15px 0px;
  cursor: pointer;
}
@media screen and (max-width: 1025px) {
  .beesThumbnailCard {
    width: 95%;
    height: auto;
    margin: 10px 0px 0px 10px;
  }
}
.beesThumbnailCard img {
  object-fit: contain;
}
.modalBees {
  position: absolute;
  top: 80px;
  left: 15%;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  color: white;
  font-family: Roboto;
  background-color: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(10px);
}
@media screen and (max-width: 1025px) {
  .modalBees {
    width: 100%;
    height: 100vh;
    left: 0%;
  }
}

.topCenter {
  position: absolute;
  top: 30px;
  left: 50px;
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
}

@media screen and (max-width: 1025px) {
  .topCenter {
    position: absolute;
    top: 20px;
    left: 50px;
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
  }
}

.scroll-to-top {
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  animation: fadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
}
