@import url("https://fonts.googleapis.com/css2?family=Corben:wght@700&family=Merriweather:ital,wght@0,400;1,300;1,700&display=swap");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  margin: 0;
  padding: 0;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: transparent;
}
h1 {
  height: auto;
  font-size: 2.5rem;
  /*z-index: 10;*/
  font-weight: normal;
  font-family: "Road Rage";
  /*line-height: 2.5rem;*/
  text-align: center;
  width: 100%;
  height: auto;
  margin-bottom: 0px;
  margin-top: 15px;
  /*margin-top:70px;*/
  color: rgb(0, 87, 63);
}
h2 {
  width: 500px;
  height: auto;
  font-family: Besley;
  font-weight: 900;
  font-size: 1.7rem;
  margin-top: 10px;
  margin-bottom: 0px;
  color: rgb(0, 87, 63);
}

@media screen and (max-width: 1025px) {
  h2 {
    width: 320px;
  }
}
h3 {
  font-family: Lato;
  color: rgb(105, 105, 105);
  font-weight: 600;
  padding: 0.5rem;
  font-size: 1.7rem;
  line-height: 2.5rem;
  text-align: center;
}
h4 {
  color: rgb(105, 105, 105);
  text-align: center;
  font-family: Lato;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 10px;
  /*padding: .5rem .5rem .5rem;*/
}
h5 {
  color: white;
  /*font-family: "Oxygen";*/
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  padding: 2rem 1rem 3rem;
}

.i-top {
  margin-top: 70px;
  background-color: transparent;
}
/*.i-topStore {
	margin-top:70px;
}*/
.i-topNobkgnd {
  margin-top: 80px;
}
.i-std-text {
  color: black;
  padding: 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
}
.shadow {
  -webkit-box-shadow: 3px 3px 5px 6px #ccc; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 3px 3px 5px 6px #ccc; /* Firefox 3.5 - 3.6 */
  box-shadow: 3px 3px 5px 6px #ccc; /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}
.i-flex-cntnr {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  /*background-color:#FFFCF4; */
}
@media screen and (max-width: 1000px) {
  .i-flex-cntnr {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.i-btn-inline {
  color: orange;
  font-size: 20px;
}
.darken {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}
.newslettersubscr {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
}
.subscrButton {
  width: 400px;
  height: 100px;
  background-size: auto;
}
.subscrButton p {
  font-weight: bold;
  font-size: 12px;
}
/*.img-ctr {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }*/
.mcHomeContainer {
  width: 75%;
  height: auto;
}
/*.mcCntnrVideo {
  display:flex;
  flex-direction: column;
  align-items: center;
  background-color:white;
  width:100%;
  height:auto;
}*/
.mcUnitVideo {
  width: 100%;
  /*height:450px;*/
  height: auto;
  margin: 10px 0px 10px 0px;
}
.testCntnr {
  width: 100%;
  height: auto;
}
.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1025px) {
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding-top: 10px;
    padding-left: 0px;
  }
}
.player-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /*padding-top:35px;*/
  /*height:0;
   overflow:hidden;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
