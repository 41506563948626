/*ul {
  list-style-type:"-";
  list-style-position:inside;
}*/
.supportCntnr1 {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	width: 100%;
}

@media screen and (max-width: 1025px) {
	.supportCntnr1 {
		flex-direction: column;
		align-items: center;
		width: 100%;
	}
}


.aboutFlex {
	width: 55%;
	display: flex;
	flex-direction: column;
	align-items:center;
	justify-content:flex-start;
	box-sizing: border-box;
	padding: 10px;
}

@media screen and (max-width: 1025px) {
	.aboutFlex {
		width:100%;
		margin:0px 0px 30px 20px;
		align-items:center;

	}
}

.aboutTitle {
	font-size: 1.2rem;
	color: black;
	text-align: left;
	line-height: 180%;
	padding: 5px 5px 0px 10px;
	background-color: white;
	width: 90%;
}

.aboutText {
	font-size: 1.2rem;
	color: black;
	text-align: left;
	line-height: 150%;
	padding: 5px 5px 0px 10px;
	background-color: white;
	width: 90%;
}

.unitContact {
	display:flex;
	flex-direction:column;
	align-items: center;
	width: 45%;
	height:auto;
	font-size: 1.2rem;
	/* height:auto;*/
	padding: 5px;
	margin: 0px 0px 0px 15px
		/*margin-bottom:20px; Doesnt work used style on div */
}

.unitContact p {
	font-size: 1.2rem;
}

@media screen and (max-width: 1025px) {
	/*.unitSupport {
		width: 95%;
		padding-left: 0px;
	}*/

	.unitContact {
		display:flex;
		flex-direction:column;
		width: 100%;
		height:auto;
		/*align-items: flex-start;*/
		padding: 10px;
	}
}

/*.supportHdr {
    font-size: 2rem;
    font-weight: bold;
    color:green;
    background-color:rgb(241,230,178);

}*/
.accordion {
	/*width: 90%;*/
	width: 575px;
	/*background-image: linear-gradient(to right, rgba(255,215,0,.8),rgba(255,165,0,.7));*/
	margin: 5px 0px 10px 0px;
}

@media screen and (max-width: 1025px) {
	.accordion {
		width: 100%;
		margin: 5px 3px 10px 3px;
	}
}

.accordion-title {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 5px 5px 5px 5px;
	cursor: pointer;
	background-color: rgb(241, 230, 178);
	color: black;
	margin-bottom: 10px;
	margin-top: 10px;

}

.accordion-title:hover {
	background-color: rgb(196, 98, 45);
}

.accLin {
	font-size: 1.3rem;
	color: white;
	text-align: left;
	padding: 5px 5px 0px 10px;
	background-color: rgb(101, 141, 77);
	width: 70%;
}

.accLin p {
	color: white;
}

.suppInfo {
	/*display: flex;
    flex-direction: column;*/
	align-items: center;
	font-size: 1.2rem;
	margin-bottom: 10px;
	margin-top: 5px;
	width: 90%;
	color: white;
	height: auto;
	/*background-color:rgb(167,171,77);*/
}

.suppInfo p {

	color: white;
}

.contactInfo {
	font-size: 1.2rem;
	color: white;
	padding: 5px 5px 5px 15px;
	margin: 0px 10px 0px 0px;
	/*background-color:rgb(99,81,61);*/
	background-color: rgb(101, 141, 27);

	width: 575px;
	/*margin-bottom:2px;*/
}

@media screen and (max-width: 1025px) {
	.contactInfo {
		width: 100%;
		margin: 0px 4px 0px 4px;
	}
}


/*.accordion-title,
  .accordion-content {
    padding:.5rem;
  }*/
.accord-text {
	font-size: 1.2rem;
	color: black;
	background: white;
	width: 90%;
}

/* .accordion-title {
    padding:5px 5px 2px 5px;
    width:100%;
  }*/

@media screen and (max-width: 1025px) {
	.accordion {
		width: 100%;
		align-items: center;
		margin: 15px 0px 15px 0px;
	}

	.accordion-title {
		padding: 5px 5px 5px 5px;
		margin: 4px;
		width: 90%;
		margin-bottom: 15px;
	}

	.accord-text {
		padding: 10px;
	}

	.faqText {
		font-size: 1.2rem;
		width: 100%;
	}
}

.faqText {
	font-size: 1.2rem;
	width: 100%;
}

.faqToggle {
	font-size: 2rem;
	padding: 5px 5px 0px 5px;
}