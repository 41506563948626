.homeTop {
  margin: 70px 0px 0px 0px;
  width: 100%;
  height: 100vh;
  /*background-image:url("../img/farmhouse2022.png") no-repeat center center fixed;*/
}
@media screen and (max-width: 1025px) {
  .homeTop {
    margin: 70px 0px 0px 0px;
    width: 100%;
    height: auto;
    /*background-image:url("../img/farmhouse2022.png") no-repeat center center fixed;*/
    /* background-image: url("../img/farmhouse20240621b.png");
    background-size: cover;
    background-attachment: fixed;*/
  }
}
.homeContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  /*height: calc(100% - 70px);*/
  flex-wrap: nowrap;
  margin-top: 70px;
  overflow: auto;
  background-image: url("../img/farmhouse20240621b.png");
  background-size: cover;
  background-attachment: fixed;
  /*background: grey;*/
  position: fixed;
  /*z-index: 10;*/
  top: 0px;
  width: 100%;
}
.teaflex {
  /*display: flex;
  flex-direction: column;
  align-items: center;*/
  position: fixed;
  top: 100px;
  left: 600px;
  bottom: 50px;
  /*width: 500px;
  height: 500px;*/
  z-index: -1;
}
@media screen and (max-width: 1025px) {
  .teaflex {
    position: fixed;
    top: 100px;
    left: 1px;
    bottom: 50px;
    /*width: 500px;
		height: 500px;*/
    z-index: -1;
  }

  .teaimg {
    display: block;
    margin-left: 200px;
    margin-right: 200px;
    width: 20%;
  }
}

/*@media screen and (max-width: 1025px) {
  .homeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    /*background-image: url("../img/farmhousers.jpg");
    background-size: cover;
    background-attachment: fixed;*/
/*background: white;
  }
}*/
/*.homeBtns {
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 500px;
  align-items: center;
  margin: 30px 10px 10px 30px;
}*/
.homeBtns {
  display: flex;
  flex-direction: column;
  width: 230px;
  margin: 0px 10px 0px 0px;
  justify-content: flex-start;
  height: 100vh;
  padding: 40px 0px 0px 10px;
  background-color: rgba(167, 171, 77);
}
@media screen and (max-width: 1025px) {
  .homeBtns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 400px;
    height: 100px;
    /*align-items: center;*/
    margin: 15px 0px 10px 0px;
    padding: 10px 5px 0px 5px;
  }
}

/*.homeBtnsItems {
  box-sizing: border-box;
  border-radius: 10px;
  width: 175px;
  height: 60px;
  cursor: pointer;
  margin: 10px 10px 10px 10px;
  padding: 5px 0px 10px 15px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 1025px) {
  .homeBtnsItems {
    box-sizing: border-box;
    border-radius: 10px;
    width: 175px;
    height: 60px;
    cursor: pointer;
    margin: 10px 10px 10px 10px;
    padding: 5px 0px 10px 5px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}*/
.homeBtnsItems {
  box-sizing: border-box;
  border-radius: 10px;
  width: 160px;
  height: 60px;
  cursor: pointer;
  color: rgba(0, 87, 63);
  font-family: "Roboto";
  font-size: 1.2rem;
  font-weight: bold;
  margin: 10px 0px 10px 10px;
  padding: 10px 0px 10px 10px;
  text-align: center;
  background: rgba(0, 87, 63);
}
@media screen and (max-width: 1025px) {
  .homeBtnsItems {
    box-sizing: border-box;
    border-radius: 10px;
    width: 140px;
    height: 60px;
    cursor: pointer;
    margin: 10px 10px 10px 15px;
    padding: 0px 0px 10px 10px;
  }
}
.iBtnName {
  font-size: 1.2rem;
  font-family: "Roboto";
  font-weight: bold;
  color: white;
}
/*.homeContent {
  border: 1px solid;
  position: absolute;
  top: 15%;
  left: 25%;
  width: 55%;
  /*transform: translate(-50%, -50%);
}*/
.textContainer {
  position: fixed;
  top: 10px;
  left: 90px;
  bottom: 50px;
  padding: 10px 10px 25px 10px;
  background-color: white;
  overflow: scroll;
  width: 900px;
  margin: 0px 10px 10px 70px;
  z-index: 40;
}
@media screen and (max-width: 1025px) {
  .textContainer {
    /*position: static;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    overflow: auto;
    margin: 60px 0px 10px 3px;
    padding: 0px 1px 0px 3px;*/
    /*position: fixed;
    top: 10px;
    left: 0px;
    padding: 10px 10px 0px 10px;
    background-color: black;
    width: 100%;
    height: auto;
    margin: 0px 10px 10px 0px;*/
    position: static;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    bottom: 0px;
    margin: 0px 15px 0px 0px;
    padding: 10px 10px 5px 10px;
  }
}

.titleText {
  font-size: 1.6rem;
  color: white;
  text-align: left;
  font-family: Roboto;
  line-height: 180%;
  padding: 5px 5px 0px 10px;
  width: 100%;
}
.textStd {
  font-size: 1.4rem;
  color: black;
  text-align: left;
  line-height: 150%;
  padding: 5px 20px 0px 10px;
  width: 100%;
  word-wrap: break-word;
}
@media screen and (max-width: 1025px) {
  .textStd {
    font-size: 1.4rem;
    color: black;
    text-align: left;
    line-height: 150%;
    padding: 5px 0px 0px 10px;
    width: 100%;
    height: auto;
    margin-right: 3px;
    white-space: normal;
  }
}
.btnPlan {
  position: fixed;
  top: 100px;
  left: 20px;
  font-family: Roboto;
  /*text-shadow: 3px 3px 20px black;*/
  font-size: 2rem;
  color: white;
  font-weight: 500;
  background: green;
  padding: 10px 10px 10px 10px;
  border-radius: 15px;
}
.btn-close {
  position: fixed;
  top: 5px;
  left: 1150px;
  background: black;
  width: 40px;
  border-radius: 20px;
  border: 3px solid white;
  /*z-index: 2;*/
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
}
@media screen and (max-width: 1025px) {
  .btn-close {
    position: fixed;
    top: 20px;
    left: 345px;
    background: black;
    width: 40px;
    border-radius: 20px;
    z-index: 5;
    color: white;
    font-size: 2rem;
    font-weight: 600;
  }
}

.home-vid-size {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*width: 80%;*/
  width: 1000px;
  height: auto;
  margin: 0px 10px 10px 150px;
}

@media screen and (max-width: 1025px) {
  .home-vid-size {
    width: 100%;
    justify-content: flex-start;

    margin: 0px 0px 15px 0px;
  }
}
.home-react-player {
  position: absolute;
  top: 30px;
  left: 0px;
  width: 100%;
  height: auto;
}
@media screen and (max-width: 1025px) {
  .home-react-player {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    height: auto;
    padding-top: 10px;
    padding-left: 0px;
  }
}
.home-player-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /*padding-top:35px;*/
  /*height:0;
	 overflow:hidden;*/
}
.modal-bg {
  position: absolute;
  top: 80;
  left: 15%;
  width: 100vw;
  height: 100vh;
  color: white;
  font-family: Roboto;
  background-color: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(10px);
}
@media screen and (max-width: 1025px) {
  .modal-bg {
    position: absolute;
    top: 80;
    left: 0px;
    width: 100%;
    height: auto;
    color: white;
    font-family: Roboto;
    background-color: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(10px);
  }
}
