.h3Weddings {
  height:auto;
  font-family:Rochester;
  font-weight:600;
  width:400px;
  font-size: 2rem;
  margin-top:30px;
  margin-bottom:20px;
  color:white;
  background-color:rgb(0,87,63);
  letter-spacing:3px;
  text-align:center;
  padding:5px 0px 10px 0px;
  border-radius:10px;
}
@media screen and (max-width: 1025px) {
.h3Weddings {
	width:80%;
	text-align:center;

	font-size: 1.5rem;
}
}
.wdngflexctr {
	height:auto;
  font-family:Rochester;
  font-weight:600;
  font-size: 2rem;
  margin-top:40px;
  margin-bottom:20px;
  width:400px;
  color:white;
  background-color:rgb(0,87,63);
  letter-spacing:5px;
  text-align:center;
  padding:10px;
  border-radius:10px;
}
.wdng-Lctns {
		display: flex;
		  flex-direction: column;
		  align-items:center;
		  background-color:white;
		  width:100%;
		  margin:0px;
}
.wdngsTextStd {
	width:80%;
	font-family:roboto;
	font-size:1.4rem;
	color:black;
	text-align:left;
	margin:15px 5px 0px 5px;
	padding:0px 20px 10px 20px;
}
@media screen and (max-width: 1025px) {

.wdngsTextStd {
	width:95%;
	font-family:roboto;
	font-size:1.4rem;
	color:black;
	margin:15px 10px 0px 10px;
	padding:0px 5px 10px 0px;
}
}


.wdngs-cntnr {
    display: flex;
      flex-direction: row;
      justify-content: center;
      background-color:white;
      width:100%;
	  margin:0px;
	  padding:0px 10px 0px 0px;
    }
	.wdngs-cntnr-rvrs {
		display: flex;
		  flex-direction: row;
		  justify-content: center;
		  background-color:white;
		  width:100%;
		  margin:0px;
		}

	@media screen and (max-width: 1025px) {

	.wdngs-cntnr {
		display: flex;
		  flex-direction:column;
		  align-items:center;
		  background-color:white;
		  width:100%;
		  margin:0px;
		}
	.wdngs-cntnr-rvrs {
			display: flex;
			  flex-direction: column-reverse;
			  align-items: center;
			  background-color:white;
			  width:100%;
			  margin:0px;
			}
	
	}
    .wdngs-flexCol {
      display:flex;
      flex-direction: column;
      /*justify-content:flex-start;*/
      align-items:center;
      width: 100%;
      height:auto;
     /* box-sizing:border-box;*/
	  margin:0px;
    }
	.wdngs-flexCol img {
	max-width:100%;
	}
	.wdngsAltLctns {
	display:flex;
	flex-direction:row;
	justify-content:space-evenly;
	width:100%;
	}
	.wdngsAltCol {
		display:flex;
		flex-direction:column;
		justify-content:center;
		width:60%;
		height:auto;
		margin:0px;
	}
	/*.wdngsAltCol img {
		max-width:100%;
	}*/
	.wdngs-rentals {
	font-size:1.3rem;
	text-align:left;
	font-weight:bold;
	margin-top:50px 0px 0px 0px;
	}
	.wdngs-rentals2 {
		font-size:1.2rem;
		text-align:left;
		padding:5px 15px 10px 15px;
		}
	.ulAltLctns {
	display:flex;
	flex-direction:row;
	justify-content:center;
	width:100%;
	}
	.liAltLctns {
	width:300px;
	height:auto;
	padding:15px;
	}
    .wdngs-gallery {
      display:flex;
      flex-flow:row wrap;
      /*align-content:space-around;*/
      /*align-content:flex-start;*/
      justify-content:center;
     /* width:50%;*/
      box-sizing:border-box;
    }
    .wdngs-img {
      width:300px;
      height:auto;
      padding:3px;
      margin:0px;
    }
    .wdngsRespImg {
      width:100%;
    }