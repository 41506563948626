body {
  background-color: white;
  padding: 0;
  margin: 0;
}
html {
  background-color: white;
  background-size: cover;
}
h1 {
  color: black;
  font-size: 3rem;
}
.glryTop {
  margin-top: 80px;
}
@media screen and (max-width: 1025px) {
  .glryTop {
    margin-top: 100px;
  }
}

.click4Enlrg {
  display: block;
  width: 80%;
  font-size: 1.5rem;
  font-style: italic;
  text-align: center;
  font-weight: 500;
  margin-top: 20px;
}
.mainCntnr {
  display: flex;
  flex-direction: row;
  width: 90%;
  height: auto;
  justify-content: flex-start;
  padding: 10px;
}
@media screen and (max-width: 1025px) {
  .mainCntnr {
    flex-direction: column;
    justify-content: center;
    width: 90%;
    background-color: white;
    background-size: cover;
  }
}
.gLogo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px;
  background: green;
  margin: 5px 0px 20px 0px;
  border-radius: 50%;
}
.glryLogo {
  width: 55px;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0px 10px 0px 10px;
  margin-left: 0px;
  color: white;
}
.titleCollections {
  display: none;
  font-size: 1.3rem;
  font-weight: 600;
  color: white;
}
.collPress {
  display: none;
}
@media screen and (max-width: 1025px) {
  .titleCollections {
    display: inline-block;
    background-color: green;
    color: white;
    padding: 20px;
    border-radius: 15px;
    margin: 0px 0px 20px 0px;
  }

  .collPress {
    display: Block;
    font-size: 1rem;
  }
  .collTitle {
    font-size: 1.8rem;
    font-weight: 900;
    text-align: center;
  }
}

.glryCtgry {
  display: flex;
  flex-direction: column;
  width: 15%;
  height: auto;
  align-items: center;
  margin: 15px 20px 0px 10px;
  padding: 0px 0px 0px 0px;
}
@media screen and (max-width: 1025px) {
  .glryCtgry {
    width: 100%;
    justify-content: center;
    margin: 0px 0px 0px 0px;
  }
}
.glryCtgryUl {
  flex-direction: column;
  align-items: center;
  padding: 5px;
  margin-left: 0px;
  height: auto;
  width: 100%;
}
@media screen and (max-width: 1025px) {
  .glryCtgryUl {
    justify-content: center;
    margin: 0px;
  }
}
.ctgryClosed {
  display: flex;
}
@media screen and (max-width: 1025px) {
  .ctgryClosed {
    display: none;
  }
}

.ctgryOpen {
  display: Flex;
}
#collOn {
  display: flex;
}

.glryCtgryLi {
  font-size: 1.1rem;
  color: white;
  padding: 10px 0px 10px 10px;
  margin: 0px 0px 15px 0px;
  width: 100%;
  font-weight: 600;
  background-color: rgb(0, 87, 63);
  font-family: "Nunito", sans-serif;
  cursor: pointer;
}
@media screen and (max-width: 1025px) {
  .glryCtgryLi {
    padding: 10px 0px 10px 0px;
    margin: 0px 0px 15px 0px;
    width: 100%;
    font-weight: 600;
  }
}
.glryCtgryLi i {
  text-align: left;
}

.glryCntnr {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 85%;
  height: auto;
  justify-content: center;
  color: black;
}
.glryItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  max-height: 300px;
  padding: 20px 0px 0px 15px;
  margin: 10px 0px 15px 25px;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
}
/*.glryItem img {
	max-width:300px;
	max-height:280px;
	filter: drop-shadow(2px 2px 4px rgb(128,128,128));
	}*/
@media screen and (max-width: 1025px) {
  .glryCntnr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    flex-wrap: none;
    width: 100%;
    height: auto;
    /*justify-content: center;*/
    color: black;
  }
  .glryItem {
    padding: 20px 0px 0px 0px;
    margin: 0px 0px 25px 0px;
  }
}
.imgFmt {
  max-width: 300px;
  max-height: 280px;
  filter: drop-shadow(2px 2px 4px rgb(128, 128, 128));
}
@media screen and (max-width: 1025px) {
  .imgFmt {
  }
}

.photoName {
  font-size: 1rem;
  color: black;
  padding: 15px 0px 0px 10px;
  font-weight: 600;
}
.photoDetails {
  font-size: 1rem;
  color: black;
  font-weight: 400;
  padding: 0px 0px 0px 0px;
}

.glryUlFlex {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 5px;
}
@media screen and (max-width: 1025px) {
  .glryUlFlex {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;
  }
  .glryOpen {
    display: flex;
  }
  .glryClosed {
    display: none;
  }

  #dsplyLi {
    display: flex;
  }
}

/*.gallery-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}*/

.App-link {
  color: #61dafb;
}
body,
ul {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #484848;
}
